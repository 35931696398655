import type { Address } from '../types/address'

import { useFormatAddress } from './useFormatAddress'

export function useInlineFormatAddress(
  address: Address,
  {
    separator = ', ',
    includeCompany = true,
    includeCountry = true,
    includeName = false,
  } = {},
) {
  return useFormatAddress(address, {
    includeCompany,
    includeCountry,
    includeName,
  }).join(separator)
}
